import { Card, CardBody, Text } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  title: string;
  description: string;
}

const TourGuideContent: FC<Props> = ({ title, description }) => {
  return (
    <Card width="100%" boxShadow={"none"} bg={"white"}>
      <CardBody>
        <Text fontSize="2xl" fontWeight="bold" mb={2} color={"black"}>
          {title}
        </Text>
        <Text fontSize="sm" mb={4} color="black" textAlign="justify">
          {description}
        </Text>
      </CardBody>
    </Card>
  );
};

export default TourGuideContent;
