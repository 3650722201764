import { gql } from "urql";

export const GET_NOTIFICATIONS = gql`
  query get_notifications(
    $user_id: uuid!
    $read_notification_limit: Int!
    $read_notification_offset: Int!
    $unread_notification_limit: Int!
    $unread_notification_offset: Int!
  ) {
    read_notifications: system_notifications(
      where: { send_to: { _eq: $user_id }, read_at: { _is_null: false } }
      limit: $read_notification_limit
      offset: $read_notification_offset
      order_by: { created_at: desc }
    ) {
      id
      title
      description
      link
      created_at
      read_at
      send_by_user {
        id
        first_name
        last_name
        organization {
          id
          name
        }
      }
      send_to_user {
        id
        first_name
        last_name
        organization {
          id
          name
        }
      }
    }
    unread_notifications: system_notifications(
      where: { send_to: { _eq: $user_id }, read_at: { _is_null: true } }
      limit: $unread_notification_limit
      offset: $unread_notification_offset
      order_by: { created_at: desc }
    ) {
      id
      title
      description
      link
      created_at
      read_at
      send_by_user {
        id
        first_name
        last_name
        organization {
          id
          name
        }
      }
      send_to_user {
        id
        first_name
        last_name
        organization {
          id
          name
        }
      }
    }
    read_notification_count: system_notifications_aggregate(
      where: { send_to: { _eq: $user_id }, read_at: { _is_null: false } }
    ) {
      aggregate {
        count
      }
    }
    unread_notification_count: system_notifications_aggregate(
      where: { send_to: { _eq: $user_id }, read_at: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const MARK_AS_READ = gql`
  mutation mark_as_read($id: uuid!, $read_at: timestamptz!) {
    update_system_notifications(
      where: { id: { _eq: $id } }
      _set: { read_at: $read_at }
    ) {
      affected_rows
    }
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation mark_all_as_read($current_user_id: uuid!, $read_at: timestamptz!) {
    update_system_notifications(
      where: { send_to: { _eq: $current_user_id }, read_at: { _is_null: true } }
      _set: { read_at: $read_at }
    ) {
      affected_rows
    }
  }
`;
