import { ReactNode } from "react";
import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { ThemeContext } from "../hooks/theme-context";

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const codeBgColor = useColorModeValue("gray.200", "gray.700");
  const headerLinkColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const headerBg = useColorModeValue("light.header", "dark.header");

  return (
    <ThemeContext.Provider
      value={{
        colorMode,
        toggleColorMode,
        bg,
        textColor,
        codeBgColor,
        headerLinkColor,
        headerBg,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
