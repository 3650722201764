import { Center, Flex, Text } from "@chakra-ui/react";

import Header from "./Header";
import Support from "../components/common/Support";

const Layout = ({
  isAuthenticated,
  children,
}: {
  isAuthenticated: boolean;
  children: React.ReactNode;
}) => {
  const currentYear = new Date().getFullYear();
  return (
    <Flex direction="column" minH="100vh">
      <Header isAuthenticated={isAuthenticated} />
      <Flex direction="row" mt="70px" flex="1" p={0} overflow="auto">
        {children}
      </Flex>
      <Center p={5} w="full">
        <Text fontSize="small" fontWeight="bold">
          Copyright &copy; {currentYear} Contentment Foundation
        </Text>
      </Center>
      <Support />
    </Flex>
  );
};

export default Layout;
