import { useMemo } from "react";
import { Flex, useColorModeValue, useTheme } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { useThemeContext } from "../hooks/theme-context";
import Logo from "../components/common/Logo";
import Navbar from "./Navbar";
import Settings from "./Settings";
import MobileNavbar from "./MobileNavbar";

interface Props {
  isAuthenticated?: boolean;
}

const Header = ({ isAuthenticated = false }: Props) => {
  const theme = useTheme();
  const { headerBg } = useThemeContext();
  const location = useLocation();

  const isNavbarRequired = useMemo(
    () =>
      !(
        location.pathname.includes("signup") ||
        location.pathname.includes("verify-email")
      ) && isAuthenticated,
    [location, isAuthenticated],
  );

  return (
    <Flex
      align="center"
      w="full"
      position="fixed"
      p={4}
      bg={headerBg}
      zIndex={10}
      boxShadow="md"
      height="70px"
      borderBottom="1px solid"
      borderColor={useColorModeValue("#D9D9D9", "#515155")}
      justify="space-between"
    >
      <Flex align="center">
        <Logo />
        {isNavbarRequired && (
          <Flex
            flex="1"
            justify="center"
            align="center"
            ml={24}
            sx={theme.components.VisibleOnPc.baseStyle}
          >
            <Navbar />
          </Flex>
        )}
      </Flex>
      <Flex sx={theme.components.VisibleOnPc.baseStyle} align="center">
        <Settings isAuthenticated={isAuthenticated} />
      </Flex>
      <MobileNavbar
        isAuthenticated={isAuthenticated}
        isNavbarRequired={isNavbarRequired}
      />
    </Flex>
  );
};

export default Header;
