export const formatGraphQLError = (message?: string): string => {
  if (!message?.trim()) return "";
  let result: string = "";
  result = message?.replace("[GraphQL] ", "").trim();

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const handleGraphQLError = (error: Error) => {
  // Return early if error message is present
  if (error?.message) {
    return formatGraphQLError(error?.message);
  }
  // Fallback for unknown errors
  return "Internal server error. Please try again later.";
};
