export const env: Record<string, unknown> = {
  recordEvents: import.meta.env.VITE_RECORD_EVENTS || false,
  apiURL:
    import.meta.env.VITE_API_URL ||
    "https://apis-dev-v2.contentment.org/v1/graphql",
  graphqlURL:
    import.meta.env.VITE_GRAPHQL_URL ||
    "https://gql-dev-v2.contentment.org/v1/graphql",
  subscriptionsURL:
    import.meta.env.VITE_GRAPHQL_SUBSCRIPTION_URL ||
    "wss://gql-dev-v2.contentment.org/v1/graphql",
  sentryDSN: import.meta.env.VITE_SENTRY_DSN || "",
};
