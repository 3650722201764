import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { listAnatomy as parts } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";
import homeBannerPc from "./assets/homepage_pc.png";
import homeBannerMob from "./assets/homepage_mob.png";
import schoolReflectionBannerPc from "./assets/school_reflection_pc.png";
import schoolReflectionBannerMob from "./assets/school_reflection_mob.png";
import personalReflectionBannerPc from "./assets/personal_reflection_pc.png";
import personalReflectionBannerMob from "./assets/personal_reflection_mob.png";
import { HOME_PAGE } from "./constants/story_ark";
import {
  PERSONAL_REFLECTION_PAGE,
  SCHOOL_REFLECTION_PAGE,
} from "./constants/reflection";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const colors = {
  brand: {
    primary: "#2FC0CF",
    secondary: "#54BF97",
  },
  dark: {
    header: "#012937",
    listBg: "#4D6B74",
    listItemHover: "#5E7D88",
    buttonHover: "#3BAAB3",
  },
  light: {
    header: "#FFFFFF",
    listBg: "#FFFFFF",
    listItemHover: "#E2E8F0",
    buttonHover: "#1C9CAA",
  },
  button: "#32C0CF",
};

const styles = {
  global: (props: { colorMode: string }) => ({
    body: {
      bg: props.colorMode === "dark" ? "#002B39" : "gray.100",
      color: props.colorMode === "dark" ? "whiteAlpha.900" : "gray.800",
    },
  }),
};

/**
 * Input styles applied to `Input`, `Select`, and other related components
 */
const inputStyle = (props: { colorMode: string }) => ({
  field: {
    bg: props.colorMode === "dark" ? "gray.700" : "gray.50",
    color: props.colorMode === "dark" ? "whiteAlpha.900" : "gray.800",
    borderColor: props.colorMode === "dark" ? "#D9D9D9" : "#515155",
    _hover: {
      borderColor: props.colorMode === "dark" ? "gray.600" : "gray.300",
    },
    _focus: {
      borderColor: props.colorMode === "dark" ? "teal.500" : "teal.300",
      boxShadow:
        "0 0 0 1px " + (props.colorMode === "dark" ? "teal.500" : "teal.300"),
    },
    _placeholder: {
      color: props.colorMode === "dark" ? "whiteAlpha.600" : "gray.500",
    },
  },
});

/**
 * Banner background image logic
 */
const getBanner = (page: string, isSmallScreen: boolean) => {
  switch (page) {
    case HOME_PAGE:
      return isSmallScreen ? homeBannerMob : homeBannerPc;
    case PERSONAL_REFLECTION_PAGE:
      return isSmallScreen
        ? personalReflectionBannerMob
        : personalReflectionBannerPc;
    case SCHOOL_REFLECTION_PAGE:
      return isSmallScreen
        ? schoolReflectionBannerMob
        : schoolReflectionBannerPc;
    default:
      return null;
  }
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const listBaseStyle = definePartsStyle((props) => ({
  container: {
    bg: mode("light.listBg", "dark.listBg")(props),
    borderRadius: "md",
    borderWidth: "1px",
    borderColor: "gray.200",
    zIndex: 1,
    position: "absolute",
    top: "100%",
    mt: 1,
    boxShadow: "lg",
    maxHeight: "200px",
    overflowY: "auto",
  },
  item: {
    cursor: "pointer",
    p: 2,
    color: mode("gray.800", "whiteAlpha.900")(props),
    _hover: {
      bg: mode("light.listItemHover", "dark.listItemHover")(props),
    },
  },
}));

const listTheme = defineMultiStyleConfig({ baseStyle: listBaseStyle });

const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
    },
    sizes: {
      md: {
        h: 10,
        minW: 10,
        fontSize: "md",
        px: 6,
      },
    },
    variants: {
      solid: (props: { colorMode: string }) => ({
        bg: "button",
        color: props.colorMode === "dark" ? "white" : "white",
        _hover: {
          bg: mode("light.buttonHover", "dark.buttonHover")(props),
          color: "white",
        },
      }),
      subtle: (props: { colorMode: string }) => ({
        bg: props.colorMode === "dark" ? "#515155" : "#2FC0CF",
        color: "white",
      }),
      solidWhite: () => ({
        bg: "button",
        color: "white",
        _hover: {
          bg: "#e2e8f0",
          color: "white",
          _disabled: {
            bg: "button",
          },
        },
      }),
      outline: (props: { colorMode: string }) => ({
        borderColor: props.colorMode === "dark" ? "#D9D9D9" : "#515155",
        color: props.colorMode === "dark" ? "white" : "gray.700",
        _hover: {
          bg: props.colorMode === "dark" ? "#515155" : "#2FC0CF",
        },
      }),
      deactivate: (props: { colorMode: string }) => ({
        bg: mode("gray.200", "gray.700")(props),
        color: "red.600",
        borderColor: "red.400",
        _hover: {
          bg: mode("red.100", "red.600")(props),
          color: "white",
        },
      }),
      confirm: (props: { colorMode: string }) => ({
        bg: mode("red.500", "red.600")(props),
        color: mode("white", "gray.200")(props),
        _hover: {
          bg: mode("red.600", "red.700")(props),
        },
      }),
    },
  },
  Checkbox: {
    baseStyle: (props: { colorMode: string }) => ({
      control: {
        bg: props.colorMode === "dark" ? "gray.700" : "gray.50",
        borderColor: props.colorMode === "dark" ? "#D9D9D9" : "#515155",
        borderWidth: "1px",
      },
    }),
  },
  Textarea: {
    variants: {
      outline: (props: { colorMode: string }) => ({
        bg: props.colorMode === "dark" ? "gray.700" : "gray.50",
        color: props.colorMode === "dark" ? "whiteAlpha.900" : "gray.800",
        borderColor: props.colorMode === "dark" ? "#D9D9D9" : "#515155",
        _hover: {
          borderColor: props.colorMode === "dark" ? "gray.600" : "gray.300",
        },
        _focus: {
          borderColor: props.colorMode === "dark" ? "teal.500" : "teal.300",
          boxShadow:
            "0 0 0 1px " +
            (props.colorMode === "dark" ? "teal.500" : "teal.300"),
        },
        _placeholder: {
          color: props.colorMode === "dark" ? "whiteAlpha.600" : "gray.500",
        },
      }),
      subtle: (props: { colorMode: string }) => ({
        borderColor: props.colorMode === "dark" ? "#515155" : "#515155",
        bg: props.colorMode === "dark" ? "#515155" : "#F2F4F7",
      }),
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderWidth: "1px",
        borderRadius: "md",
      },
    },
    variants: {
      outline: inputStyle,
    },
    defaultProps: {
      variant: "outline",
    },
  },
  Select: {
    baseStyle: {
      field: {
        borderWidth: "1px",
        borderRadius: "md",
      },
    },
    variants: {
      outline: inputStyle,
    },
    defaultProps: {
      variant: "outline",
    },
  },
  FormLabel: {
    baseStyle: {
      requiredIndicator: {
        color: "red.500",
      },
    },
  },
  Divider: {
    baseStyle: (props: { colorMode: string }) => ({
      borderColor: props.colorMode === "dark" ? "whiteAlpha.600" : "gray.500",
    }),
  },
  IconButton: {
    baseStyle: {
      borderRadius: "md",
    },
    variants: {
      reset: inputStyle,
    },
    defaultProps: {
      variant: "reset",
    },
  },
  PinInput: {
    sizes: {
      base: {
        h: 10,
        w: 10,
      },
      md: {
        h: 12,
        w: 12,
      },
    },
    baseStyle: {
      field: {
        borderRadius: "md",
        _focus: {
          boxShadow: "outline",
        },
      },
    },
    variants: {
      outline: (props: { colorMode: string }) => ({
        bg: props.colorMode === "dark" ? "gray.700" : "gray.50",
        color: props.colorMode === "dark" ? "whiteAlpha.900" : "gray.800",
        borderColor: props.colorMode === "dark" ? "#D9D9D9" : "#515155",
        _hover: {
          borderColor: props.colorMode === "dark" ? "gray.600" : "gray.300",
        },
        _focus: {
          borderColor: props.colorMode === "dark" ? "teal.500" : "teal.300",
          boxShadow:
            "0 0 0 1px " +
            (props.colorMode === "dark" ? "teal.500" : "teal.300"),
        },
        _placeholder: {
          color: props.colorMode === "dark" ? "whiteAlpha.600" : "gray.500",
        },
      }),
    },
    defaultProps: {
      variant: "outline",
    },
  },
  List: listTheme,
  Menu: {
    baseStyle: (props: { colorMode: string }) => ({
      item: {
        color: mode("gray.800", "whiteAlpha.900")(props),
        _hover: {
          bg: mode("light.listItemHover", "dark.listItemHover")(props),
        },
      },
    }),
  },
  FullWidthContainer: {
    baseStyle: {
      width: { base: "85%", lg: "35%" },
      height: "full",
    },
  },
  VisibleOnMob: {
    baseStyle: {
      display: { base: "flex", lg: "none" },
    },
  },
  VisibleOnPc: {
    baseStyle: {
      display: { base: "none", lg: "flex" },
    },
  },
  ResponsiveFlexContainer: {
    baseStyle: {
      display: "flex",
      flexDirection: { base: "column", lg: "row" },
      width: { base: "100%", md: "100%", lg: "100%" },
      height: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  ResponsiveBanner: {
    baseStyle: (props: { page: string; isSmallScreen: boolean }) => ({
      backgroundImage: getBanner(props.page, props.isSmallScreen),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "auto",
      textAlign: "left",
      "& .banner-text": {
        width: { base: "100%", md: "75%", lg: "40%" },
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
        marginBottom: 5,
        fontSize: "xl",
      },
    }),
  },
  SummaryItem: {
    baseStyle: {
      h3: {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "35px",
        marginBottom: "20px",
        "&:first-of-type": {
          marginTop: 0,
        },
      },
      p: {
        marginBottom: "10px",
        lineHeight: "normal",
      },
      "ul, ol": {
        paddingLeft: "35px",
        li: {
          marginBottom: "10px",
          "&:last-child": {
            marginBottom: "20px",
          },
        },
      },
    },
  },
  LessonContainer: {
    baseStyle: {
      "&:last-child": {
        borderBottom: "none",
        ".lessonIcon:after": {
          display: "none",
        },
      },
    },
  },
  LessonIcon: {
    baseStyle: {
      position: "relative",
      "&:after": {
        position: "absolute",
        content: '""',
        display: "block",
        backgroundColor: "#8f8f8f",
        width: "1px",
        height: "115px",
        left: "11px",
        top: "23px",
      },
    },
  },
  storyArkContainer: {
    baseStyle: {
      "&:last-child": {
        borderBottom: "none",
      },
    },
  },
  storyArkIcon: {
    baseStyle: {
      position: "relative",
      "&:before": {
        position: "absolute",
        content: '""',
        display: "block",
        backgroundColor: "brand.primary",
        width: "1.5px",
        height: "150px",
        left: "50%",
        top: "-0px",
      },
      "&:after": {
        position: "absolute",
        content: '""',
        display: "block",
        backgroundColor: "brand.primary",
        width: "1.5px",
        height: "150px",
        left: "50%",
        bottom: "-0px",
      },
    },
  },
};

const fonts = {
  body: "Lato, sans-serif",
  heading: "Lato, sans-serif",
};

const theme = extendTheme({
  config,
  colors,
  styles,
  fonts,
  components,
});

export default theme;
