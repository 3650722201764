import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Loader from "../components/common/Loader";
import { ROUTES } from "../constants/routes";
import { AuthData, LogoutResponse } from "../types/auth";
import { User } from "../types/user";
import { getToken, logout } from "../utils/auth-token";
import { isPublicPath } from "../utils/routes";
import { getDefaultRole } from "../utils/role";

interface AuthContextInterface {
  loading: boolean;
  auth?: AuthData | null;
  handleLogout: () => void;
  setAuth: (data?: AuthData) => void;
  setUser: (user: User) => void;
  refetchToken: () => void;
}

interface AuthContextProps {
  children?: ReactNode;
}

const AuthContext = createContext<AuthContextInterface>({
  auth: null,
  setAuth: () => {},
  handleLogout: () => {},
  loading: false,
  setUser: () => {},
  refetchToken: () => {},
});

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [auth, setAuth] = useState<AuthData>({
    id: "",
    token: "",
    roles: [],
    default_role: "",
  });
  const [loading, setLoading] = useState(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  let intervalRef: NodeJS.Timeout | null = null;

  const setUser = (user: User) => {
    setAuth((prevAuth) => ({ ...prevAuth, user }));
  };

  const handleLogout = async () => {
    setLoading(true);
    const res: LogoutResponse = await logout();
    if (res?.data?.logout?.message) {
      if (timeoutRef.current) clearInterval(timeoutRef.current);
      toast({
        title: "Success",
        description: res.data.logout.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      setAuth({
        id: "",
        token: "",
        roles: [],
        default_role: "",
      });
      localStorage.removeItem("default_role");
      navigate(ROUTES.root, { replace: true });
    }
    if (res?.errors?.length) {
      toast({
        title: "Error",
        description: res.errors[0].message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const fetchToken = async () => {
    try {
      const defaultRole = localStorage.getItem("default_role") || "";
      setLoading(true);
      const data = await getToken(defaultRole);
      setAuthData(data);
      // Calculate time remaining for token to expire
      const expiresIn = data?.session?.access_token_expires_at
        ? data?.session?.access_token_expires_at * 1000 - Date.now()
        : 0;
      // Fetch new auth token when the current token expires
      if (intervalRef) clearInterval(intervalRef);
      intervalRef = setInterval(async () => {
        fetchToken();
      }, expiresIn);
    } catch (err) {
      console.error(err);
      setAuth({
        id: "",
        token: "",
        roles: [],
        default_role: "",
      });
      localStorage.removeItem("default_role");
    } finally {
      setLoading(false);
    }
  };

  const setAuthData = (data?: AuthData) => {
    const tokenData = {
      ...data,
      default_role: getDefaultRole(data?.user?.user_roles || []),
    };
    setAuth(
      (tokenData as AuthData) || {
        id: "",
        token: "",
        roles: [],
        default_role: "",
      },
    );
    if (!data) {
      if (!isPublicPath(window.location.pathname)) {
        navigate(ROUTES.root, { replace: true });
      }
    }
  };

  useEffect(() => {
    fetchToken();
    return () => {
      if (intervalRef) clearInterval(intervalRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader height="100vh" width="100vw" />;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth: setAuthData,
        handleLogout,
        loading,
        setUser,
        refetchToken: fetchToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
