import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { Provider } from "urql";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { privateEndpointClient, publicEndpointClient } from "./config/graphql";
import Loader from "./components/common/Loader";
import { privateRoutes, publicRoutes } from "./utils/routes";
import { RouteType } from "./types/routes";
import { useAuthContext } from "./hooks/auth-context";
import Layout from "./layouts/Layout";
import { roleBasedAccess } from "./utils/permission";

export const Router = () => {
  const { auth } = useAuthContext();
  const location = useLocation();

  const isAuthenticated = useMemo(
    () => Boolean(auth?.id && auth?.token && auth?.user),
    [auth],
  );

  const client = useMemo(() => {
    if (!auth || !isAuthenticated) {
      return publicEndpointClient;
    } else {
      return privateEndpointClient(auth);
    }
  }, [auth, isAuthenticated]);

  const getPrivateRoutes = useCallback(
    (pr: RouteType[]): ReactNode => {
      const config = {
        type: "spring",
        damping: 20,
        stiffness: 100,
      };
      return roleBasedAccess(pr, auth?.default_role || "")?.map(
        (routeObj: RouteType) => (
          <Route
            path={routeObj.path}
            key={routeObj.path}
            element={
              <motion.div
                transition={config}
                initial={{ y: 25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -25, opacity: 0 }}
                style={{ width: "100%" }}
              >
                <routeObj.element />
              </motion.div>
            }
          >
            {routeObj.child && getPrivateRoutes(routeObj.child)}
          </Route>
        ),
      );
    },
    [auth?.default_role],
  );

  const getRoutes = useCallback(() => {
    if (isAuthenticated) {
      return getPrivateRoutes(privateRoutes());
    } else {
      return publicRoutes.map((routeObj: RouteType) => (
        <Route
          path={routeObj.path}
          key={routeObj.path}
          element={<routeObj.element />}
        />
      ));
    }
  }, [isAuthenticated, getPrivateRoutes]);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <Provider value={client}>
      <Layout isAuthenticated={isAuthenticated}>
        <AnimatePresence mode="wait" initial={false}>
          <React.Suspense fallback={<Loader height="100vh" width="100vw" />}>
            <Routes key={location.pathname}>
              {getRoutes()}
              {/* Catch-all route for 404 */}
              <Route
                path="*"
                element={<Loader height="100vh" width="100vw" />}
              />
            </Routes>
          </React.Suspense>
        </AnimatePresence>
      </Layout>
    </Provider>
  );
};
