import { Box, Button, Divider, HStack } from "@chakra-ui/react";
import React from "react";
import { StepType, useTour } from "@reactour/tour";

const TourGuideNavigation: React.FC<{
  steps: StepType[];
  currentStep: number;
}> = ({ steps, currentStep }) => {
  const { setCurrentStep, setIsOpen } = useTour();
  return (
    <Box>
      <Divider bg={"gray.500"} />
      <HStack
        justifyContent="space-between"
        spacing={4}
        h={"50px"}
        px={4}
        py={8}
      >
        <Button
          size="sm"
          ml={2}
          onClick={() => setCurrentStep(currentStep - 1)}
          isDisabled={currentStep === 0}
          width="75px"
        >
          Previous
        </Button>
        {currentStep === steps.length - 1 ? (
          <Button
            mr={2}
            size="sm"
            onClick={() => setIsOpen(false)}
            width="75px"
          >
            Finish
          </Button>
        ) : (
          <Button
            mr={2}
            size="sm"
            onClick={() => setCurrentStep(currentStep + 1)}
            width="75px"
          >
            Next
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default TourGuideNavigation;
