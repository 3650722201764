import { IPaginationType } from "../types/common";

export const getDefaultPagination = (
  pageSize: number = 10,
): IPaginationType => {
  return {
    offset: 0,
    limit: pageSize,
    currentPage: 1,
  };
};
