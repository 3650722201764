import { gql } from "urql";

export const GET_SESSION = gql`
  query session($organization_id: ID) {
    session(params: { organization_id: $organization_id }) {
      message
      access_token
      access_token_expires_at
      user {
        id
        first_name
        last_name
        picture
        email
        is_teacher
        signup_status
        user_roles {
          id
          metadata_role {
            id
            role
          }
        }
        user_tags {
          id
          metadata_tag {
            id
            tag
          }
        }
        organization {
          id
          name
          deactivated_at
          verified_at
          max_licenses
          country
          state
          city
          address_line1
          address_line2
          postal_code
        }
      }
    }
  }
`;
