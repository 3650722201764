export const ROUTES = {
  root: "/",
  forgot_password: "/forgot-password",
  reset_password: "/reset-password",
  update_password: "/profile/account-settings/update-password",
  profile: "/profile",
  personal_info: "/profile/personal-info",
  edit_personal_info: "/profile/personal-info/edit",
  certificates: "/profile/certificates",
  organization_info: "/settings/organization-info",
  edit_organization_info: "/profile/organization-info/edit",
  notifications: "/notifications",
  settings: "/settings",
  verify_email: "/verify-email",
  signup: "/signup",
  training: "/training",
  reflection: "/reflection",
  teaching: "/teaching",
  community: "/community",
  self_guided_training: "/training/self-guided-training",
  live_training: "/training/live-training",
  live_training_details: "/training/live-training-details/:cohortId",
  student_curriculum: "/teaching/student-curriculum",
  media_player: "/training/media-player/:pillarId/:lessonId",
  account_settings: "/profile/account-settings",
  manage_users: "/settings/manage-users",
  personal_reflection: "/reflection/personal",
  school_reflection: "/reflection/school",
  wellness_library: "/wellness-library",
  personal_reflection_archive: "/reflection/personal/archive",
  school_reflection_archive: "/reflection/school/archive",
  page_not_found: "*",
};
