import { createContext, ReactNode, useEffect, useState } from "react";
import { INotification } from "../types/notification";
import { useAuthContext } from "../hooks/auth-context";
import { IPaginationType } from "../types/common";
import { getDefaultPagination } from "../utils/pagination";
import { GET_NOTIFICATIONS } from "../graphql/notification";
import { privateEndpointClient } from "../config/graphql";

interface NotificationContextInterface {
  loading: boolean;
  unreadNotificationPagination: IPaginationType;
  readNotificationPagination: IPaginationType;
  unreadNotifications: INotification[];
  readNotifications: INotification[];
  unreadNotificationCount: number;
  readNotificationCount: number;
  setUnreadNotificationPagination: (value: IPaginationType) => void;
  setReadNotificationPagination: (value: IPaginationType) => void;
}

interface NotificationContextProps {
  children?: ReactNode;
}

const NotificationContext = createContext<NotificationContextInterface>({
  loading: false,
  unreadNotificationPagination: getDefaultPagination(),
  readNotificationPagination: getDefaultPagination(),
  readNotifications: [],
  unreadNotifications: [],
  readNotificationCount: 0,
  unreadNotificationCount: 0,
  setUnreadNotificationPagination: () => {},
  setReadNotificationPagination: () => {},
});

export const NotificationProvider = ({
  children,
}: NotificationContextProps) => {
  const { auth } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [unreadNotificationPagination, setUnreadNotificationPagination] =
    useState<IPaginationType>(getDefaultPagination());
  const [readNotificationPagination, setReadNotificationPagination] =
    useState<IPaginationType>(getDefaultPagination());
  const [unreadNotifications, setUnreadNotifications] = useState<
    INotification[]
  >([]);
  const [readNotifications, setReadNotifications] = useState<INotification[]>(
    [],
  );
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [readNotificationCount, setReadNotificationCount] = useState(0);

  const fetchNotifications = async () => {
    try {
      if (!auth?.id) {
        return;
      }
      setLoading(true);
      const client = privateEndpointClient(auth!);
      const { data } = await client
        .query(GET_NOTIFICATIONS, {
          user_id: auth?.user?.id,
          read_notification_limit:
            readNotificationPagination.limit *
            readNotificationPagination.currentPage,
          read_notification_offset: readNotificationPagination.offset,
          unread_notification_limit:
            unreadNotificationPagination.limit *
            unreadNotificationPagination.currentPage,
          unread_notification_offset: unreadNotificationPagination.offset,
        })
        .toPromise();
      setReadNotifications(data?.read_notifications);
      setUnreadNotifications(data?.unread_notifications);
      setUnreadNotificationCount(
        data?.unread_notification_count?.aggregate?.count,
      );
      setReadNotificationCount(data?.read_notification_count?.aggregate?.count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, readNotificationPagination, unreadNotificationPagination]);

  return (
    <NotificationContext.Provider
      value={{
        loading,
        unreadNotificationPagination,
        readNotificationPagination,
        readNotifications,
        unreadNotifications,
        readNotificationCount,
        unreadNotificationCount,
        setUnreadNotificationPagination,
        setReadNotificationPagination,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
