import { UseToastOptions } from "@chakra-ui/react";

export const toastTimeOut = 5000;

export const ToastConfiguration: UseToastOptions = {
  position: "top",
  variant: "solid",
  isClosable: true,
  duration: toastTimeOut,
};
