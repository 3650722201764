import {
  createClient as createUrqlClient,
  fetchExchange,
  cacheExchange,
  errorExchange,
  Operation,
  OperationResult,
  Exchange,
} from "urql";
import { pipe, tap, Source } from "wonka";
import { env } from "./env";
import { AuthData } from "../types/auth";
import { createAnalyticsEvent, registerEvent } from "../utils/events";
import { IGNORE_EVENTS } from "../constants/events";

export const platform = "school-platform";
export const platformHeader = { "x-platform": platform };

// generate headers for authenticated requests
const generateHeaders = (auth: { token: string }): Record<string, string> => ({
  authorization: `Bearer ${auth.token}`,
  ...platformHeader,
});

// Analytics exchange for urql.
const analyticsExchange =
  (auth?: AuthData): Exchange =>
  ({ forward }) =>
  (ops$: Source<Operation>): Source<OperationResult> =>
    pipe(
      ops$,
      tap((operation: Operation) => {
        const event = createAnalyticsEvent(operation, auth);
        // Determine the headers for the event registration.
        const headers = auth ? generateHeaders(auth) : platformHeader;
        if (!IGNORE_EVENTS.includes(event.event)) {
          // Fire and forget the analytics event.
          registerEvent(event, headers).catch((err) =>
            console.error("Analytics error:", err),
          );
        }
      }),
      forward,
    );

export const privateEndpointClient = (auth: AuthData) =>
  createUrqlClient({
    url: env.graphqlURL as string,
    requestPolicy: "cache-and-network",
    exchanges: [
      analyticsExchange(auth),
      cacheExchange,
      fetchExchange,
      errorExchange({
        onError(error) {
          console.error(error);
        },
      }),
    ],
    fetchOptions: () => ({
      headers: generateHeaders(auth),
      credentials: "include",
    }),
  });

export const publicEndpointClient = createUrqlClient({
  url: env.apiURL as string,
  exchanges: [
    analyticsExchange(),
    cacheExchange,
    fetchExchange,
    errorExchange({
      onError(error) {
        console.error(error);
      },
    }),
  ],
  fetchOptions: () => ({
    headers: platformHeader,
    credentials: "include",
  }),
});
