import { USER_ROLES } from "../constants/roles";
import { UserRole } from "../types/user";

export const getAllRoles = (availableRoles: UserRole[]) => {
  if (!availableRoles?.length) return [];
  return availableRoles.map(
    (role: { metadata_role: { role: string } }) => role.metadata_role.role,
  );
};

export const getDefaultRole = (
  availableRoles: UserRole[],
): USER_ROLES | undefined => {
  if (!availableRoles?.length) return;
  // if (
  //   availableRoles.some((role) => role.metadata_role.role === USER_ROLES.ADMIN)
  // ) {
  //   return USER_ROLES.ADMIN;
  // }
  if (
    availableRoles.some(
      (role) => role.metadata_role.role === USER_ROLES.ORGANIZATION_ADMIN,
    )
  ) {
    return USER_ROLES.ORGANIZATION_ADMIN;
  }
  if (
    availableRoles.some(
      (role) => role.metadata_role.role === USER_ROLES.STAFF_MEMBER,
    )
  ) {
    return USER_ROLES.STAFF_MEMBER;
  }
  return USER_ROLES.FAMILY_APP_MEMBER;
};
