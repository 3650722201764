import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";

type LoaderProps = {
  height?: string;
  width?: string;
  size?: "sm" | "md" | "lg" | "xl";
};

const Loader: React.FC<LoaderProps> = ({
  height = "auto",
  width = "auto",
  size = "md",
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={height}
      width={width}
    >
      <Spinner size={size} />
    </Flex>
  );
};

export default Loader;
