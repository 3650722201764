import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";

interface CrosstabContextInterface {
  values: { [key: string]: string };
  getValue: (key: string) => string | undefined;
  setValue: (key: string, value: string) => void;
  removeValue: (key: string) => void;
}

interface CrosstabContextProps {
  children?: ReactNode;
}

const CrosstabContext = createContext<CrosstabContextInterface>({
  values: {},
  getValue: () => undefined,
  setValue: () => {},
  removeValue: () => {},
});

export const CrosstabContextProvider = ({ children }: CrosstabContextProps) => {
  const [values, setValues] = useState<{ [key: string]: string }>(() => {
    const storedValues = localStorage.getItem("crosstab");
    return storedValues ? JSON.parse(storedValues) : {};
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "crosstab") {
        setValues(event.newValue ? JSON.parse(event.newValue) : {});
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const setValue = useCallback((key: string, value: string) => {
    setValues((prevValues) => {
      const newValues = { ...prevValues, [key]: value };
      localStorage.setItem("crosstab", JSON.stringify(newValues));
      return newValues;
    });
  }, []);

  const getValue = useCallback(
    (key: string) => {
      return values[key];
    },
    [values],
  );

  const removeValue = useCallback((key: string) => {
    setValues((prevValues) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _, ...newValues } = prevValues;
      localStorage.setItem("crosstab", JSON.stringify(newValues));
      return newValues;
    });
  }, []);

  return (
    <CrosstabContext.Provider
      value={{
        values,
        getValue,
        setValue,
        removeValue,
      }}
    >
      {children}
    </CrosstabContext.Provider>
  );
};

export default CrosstabContext;
