import { createContext, useContext } from "react";

interface ThemeContextProps {
  colorMode: string;
  toggleColorMode: () => void;
  bg: string;
  textColor: string;
  codeBgColor: string;
  headerLinkColor: string;
  headerBg: string;
}

export const ThemeContext = createContext<ThemeContextProps | undefined>(
  undefined,
);

export const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};
