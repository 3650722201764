import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider } from "./contexts/theme";
import theme from "./theme";
import { Router } from "./Router";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
