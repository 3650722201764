import { useMemo, useState } from "react";
import {
  Flex,
  Image,
  Text,
  Wrap,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useThemeContext } from "../hooks/theme-context";
import home from "../assets/home.svg";
import homeWhite from "../assets/home_white.svg";
import training from "../assets/training.svg";
import trainingWhite from "../assets/training_white.svg";
import reflection from "../assets/reflection.svg";
import reflectionWhite from "../assets/reflection_white.svg";
import teaching from "../assets/teaching.svg";
import teachingWhite from "../assets/teaching_white.svg";
import community from "../assets/community.svg";
import communityWhite from "../assets/community_white.svg";
import { ROUTES } from "../constants/routes";
import { USER_ROLES } from "../constants/roles";
import { NavbarMenuItem } from "../types/navbar";
import { useAuthContext } from "../hooks/auth-context";

const Navbar = () => {
  const { t } = useTranslation();
  const { colorMode, headerLinkColor } = useThemeContext();
  // State to manage hover for each menu item
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const icon = useMemo(
    () =>
      colorMode === "dark"
        ? {
            home: homeWhite,
            training: trainingWhite,
            reflection: reflectionWhite,
            teaching: teachingWhite,
            community: communityWhite,
          }
        : { home, training, reflection, teaching, community },
    [colorMode],
  );
  const { auth } = useAuthContext();
  const allUserRoles = Object.values(USER_ROLES);

  const menuItems: NavbarMenuItem[] = useMemo(
    () => [
      {
        label: "Home",
        icon: icon.home,
        path: ROUTES.root,
        tour_id: "home",
        allowedRoles: allUserRoles,
      },
      {
        label: "Training",
        icon: icon.training,
        path: ROUTES.training,
        subMenu: [
          {
            label: "Self-Guided Training",
            path: ROUTES.self_guided_training,
            allowedRoles: allUserRoles,
          },
          {
            label: "Live Training",
            path: ROUTES.live_training,
            allowedRoles: allUserRoles,
          },
        ],
        tour_id: "training",
        allowedRoles: allUserRoles,
      },
      {
        label: "Teaching",
        icon: icon.teaching,
        path: ROUTES.teaching,
        tour_id: "teaching",
        allowedRoles: allUserRoles,
      },
      {
        label: "Reflection",
        icon: icon.reflection,
        path: ROUTES.reflection,
        allowedRoles: allUserRoles,
        subMenu: [
          {
            label: "Personal Wellbeing",
            path: ROUTES.personal_reflection,
            allowedRoles: allUserRoles,
          },
          {
            label: "School Wellbeing",
            path: ROUTES.school_reflection,
            allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.ADMIN],
          },
        ],
        tour_id: "reflection",
      },
    ],
    [allUserRoles, icon],
  );

  // Filter menu items and sub-menu items based on the user's default role
  const filteredMenuItems = useMemo(() => {
    return menuItems
      .filter((item) =>
        item.allowedRoles?.includes(auth?.default_role as USER_ROLES),
      )
      .map((item) => ({
        ...item,
        subMenu: item.subMenu
          ? item.subMenu.filter((subItem) =>
              subItem.allowedRoles?.includes(auth?.default_role as USER_ROLES),
            )
          : undefined,
      }));
  }, [auth?.default_role, menuItems]);

  return (
    <Wrap align="center" spacing={12}>
      {filteredMenuItems.map((item, index) => (
        <WrapItem
          key={index}
          data-tut={item.tour_id}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <Menu isOpen={hoveredIndex === index}>
            <MenuButton to={item.path} as={ReactRouterLink}>
              <Flex flexDir="column" justify="center" align="center">
                <Image h="24px" src={item.icon as string} alt={t(item.label)} />
                <Text color={headerLinkColor} fontSize="sm">
                  {t(item.label)}
                </Text>
              </Flex>
            </MenuButton>
            {item.subMenu && (
              <MenuList
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {item.subMenu.map((subItem, subIndex) => (
                  <MenuItem
                    key={subIndex}
                    as={ReactRouterLink}
                    to={subItem.path}
                  >
                    <Text color={headerLinkColor}>{subItem.label}</Text>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Menu>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default Navbar;
