import { RouteType } from "../types/routes";

export const roleBasedAccess = (
  privateRoutes: RouteType[],
  userRole: string,
) => {
  return privateRoutes.filter((route: RouteType) =>
    route.allowedRoles?.includes(userRole),
  );
};
