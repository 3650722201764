import { publicEndpointClient } from "../config/graphql";
import { GET_SESSION } from "../graphql/session";
import { DEACTIVATE_ACCOUNT, LOGOUT } from "../graphql/users";
import {
  AuthData,
  DeactivateAccountResponse,
  LogoutResponse,
} from "../types/auth";
import { UserRole } from "../types/user";

export const getToken = async (organization_id?: string): Promise<AuthData> => {
  const res = await publicEndpointClient
    .query(
      GET_SESSION,
      {
        organization_id: organization_id,
      },
      {
        requestPolicy: "network-only",
      },
    )
    .toPromise();
  const { data } = res;
  if (data?.session) {
    const roles: string[] = data.session.user.user_roles.map(
      (roles: UserRole) => roles.metadata_role.role,
    );
    return {
      id: data.session.user.id,
      token: data.session.access_token,
      roles,
      session: data.session,
      user: data.session.user,
    };
  } else {
    throw new Error("No session found");
  }
};

export const logout = async (): Promise<LogoutResponse> => {
  try {
    const res = await publicEndpointClient.mutation(LOGOUT, {}).toPromise();
    const { data, error } = res;
    if (data) {
      return { data: { logout: { message: data.logout.message } } };
    } else if (error) {
      return { errors: [{ message: error.message }] };
    }
  } catch (err) {
    return { errors: [{ message: "Internal error, try again!" }] };
  }
  return { errors: [{ message: "Internal error, try again!" }] };
};

export const deactivateAccount =
  async (): Promise<DeactivateAccountResponse> => {
    try {
      const res = await publicEndpointClient
        .mutation(DEACTIVATE_ACCOUNT, {})
        .toPromise();
      const { data, error } = res;
      if (data) {
        return {
          data: {
            deactivate_account: { message: data.deactivate_account.message },
          },
        };
      } else if (error) {
        return { errors: [{ message: error.message }] };
      }
    } catch (err) {
      return { errors: [{ message: "Internal error, try again!" }] };
    }
    return { errors: [{ message: "Internal error, try again!" }] };
  };
