import React, { useMemo, useState } from "react";
import {
  Flex,
  Image,
  Link as ChakraLink,
  Wrap,
  WrapItem,
  // Select,
  Avatar,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Icon,
  Box,
  Switch,
  Divider,
  Badge,
  SkeletonCircle,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import NoData from "../assets/no_data.svg";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  notifications,
  notificationsWhite,
  settings,
  settingsWhite,
  darkMode,
  lightMode,
  logoutIcon,
  logoutWhiteIcon,
} from "../assets";
import { logout } from "../utils/auth-token";
import { useThemeContext } from "../hooks/theme-context";
import { useAuthContext } from "../hooks/auth-context";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CgProfile } from "react-icons/cg";
import { ROUTES } from "../constants/routes";
import { HiCheck, HiX } from "react-icons/hi";
import MaterialLink from "../assets/material_link.svg";
import MaterialLinkWhite from "../assets/material_link_white.svg";
import { useNotificationContext } from "../hooks/notification-context";
import { MARK_AS_READ } from "../graphql/notification";
import { useMutation } from "urql";
import { getDefaultPagination } from "../utils/pagination";
interface Props {
  isAuthenticated?: boolean;
}

const Settings = ({ isAuthenticated }: Props) => {
  const { auth } = useAuthContext();
  const {
    // i18n,
    t,
  } = useTranslation();
  const {
    colorMode,
    toggleColorMode,
    // headerBg
  } = useThemeContext();
  const [showUnread, setShowUnread] = useState(true);
  const {
    loading,
    readNotifications,
    unreadNotifications,
    unreadNotificationCount,
    setUnreadNotificationPagination,
    setReadNotificationPagination,
  } = useNotificationContext();
  const [, markAsReadMutation] = useMutation(MARK_AS_READ);

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  const icon = useMemo(
    () =>
      colorMode === "dark"
        ? {
            notifications: notificationsWhite,
            mode: lightMode,
            settings: settingsWhite,
            logout: logoutWhiteIcon,
            link: MaterialLink,
          }
        : {
            notifications,
            mode: darkMode,
            settings,
            logout: logoutIcon,
            link: MaterialLinkWhite,
          },
    [colorMode],
  );

  const handleLogout = async () => {
    await logout();
    window?.location && window.location.reload();
  };

  const handleMarkAsRead = async (id: string) => {
    try {
      const { data } = await markAsReadMutation({
        id,
        read_at: new Date().toISOString(),
      });
      if (data?.update_system_notifications) {
        setReadNotificationPagination(getDefaultPagination());
        setUnreadNotificationPagination(getDefaultPagination());
      }
    } catch (error) {
      console.error(error, "error while mark as read");
    }
  };

  return (
    <Wrap align="center" spacing={4}>
      {isAuthenticated && (
        <>
          {/* <WrapItem>
            <ChakraLink as={ChakraLink} onClick={handleLogout}>
              <Flex flexDir="column" justify="center" align="center">
                <Image h="24px" src={icon.logout} alt={t("logout")} />
              </Flex>
            </ChakraLink>
          </WrapItem> */}
          <WrapItem>
            <Menu placement="bottom">
              <MenuButton cursor="pointer">
                <Box position="relative">
                  <Image
                    h="24px"
                    src={icon.notifications}
                    alt={t("notifications")}
                  />
                  {unreadNotificationCount > 0 && (
                    <Badge
                      position="absolute"
                      top="-4px"
                      right="-2px"
                      bg="red.400"
                      color="white"
                      borderRadius="full"
                      fontSize="x-small"
                    >
                      {unreadNotificationCount}
                    </Badge>
                  )}
                </Box>
              </MenuButton>
              <MenuList
                w={"500px"}
                h={
                  showUnread
                    ? unreadNotifications?.length === 0
                      ? "300px"
                      : "500px"
                    : readNotifications?.length === 0
                      ? "300px"
                      : "500px"
                }
                overflowY={"scroll"}
                style={{ scrollbarWidth: "thin" }}
                p={4}
              >
                {/* Notification header */}
                <Flex justify={"space-between"} align={"center"}>
                  <Text fontSize="lg" fontWeight="bold">
                    Notifications
                  </Text>
                  <HStack spacing={4}>
                    <Text fontSize="sm">Show only unread</Text>
                    <Box
                      position="relative"
                      display="inline-flex"
                      alignItems="center"
                    >
                      <Switch
                        isChecked={!showUnread}
                        onChange={() => setShowUnread(!showUnread)}
                        size="md"
                        colorScheme={"green"}
                        aria-label="Switch with icons"
                      />
                      <Icon
                        as={showUnread ? HiX : HiCheck}
                        position="absolute"
                        onClick={() => setShowUnread(!showUnread)}
                        left={!showUnread ? "25%" : "75%"}
                        transform="translateX(-50%)"
                        boxSize={3}
                        color="white"
                      />
                    </Box>
                    <ChakraLink as={ReactRouterLink} to={ROUTES.notifications}>
                      <Flex flexDir="column" justify="center" align="center">
                        <Image
                          h="24px"
                          src={icon.link}
                          alt={t("notifications")}
                        />
                      </Flex>
                    </ChakraLink>
                  </HStack>
                </Flex>
                <Divider my={4} />
                {/* Notifications body */}
                {loading ? (
                  <React.Fragment>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <HStack w="full" mt={4} key={index}>
                        <SkeletonCircle size="10" />
                        <Stack flex="1">
                          <Skeleton height="4" />
                          <Skeleton height="3" width="80%" />
                        </Stack>
                      </HStack>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {(showUnread
                      ? unreadNotifications
                      : readNotifications
                    )?.map((notification, index) => (
                      <VStack
                        key={index}
                        spacing={4}
                        align={"start"}
                        py={4}
                        px={2}
                        _hover={{
                          bg:
                            colorMode === "dark"
                              ? "dark.listItemHover"
                              : "light.listItemHover",
                          borderRadius: "md",
                        }}
                        onClick={() => {
                          if (!notification.read_at) {
                            handleMarkAsRead(notification?.id);
                          }
                          if (notification.link) {
                            window.open(notification.link, "_blank");
                          }
                        }}
                      >
                        <HStack justify={"space-between"} spacing={2}>
                          <Avatar
                            name={`${notification?.send_by_user?.first_name} ${notification?.send_by_user?.last_name}`}
                            bg={
                              notification?.send_by_user?.picture
                                ? ""
                                : "brand.primary"
                            }
                            color="white"
                            cursor="pointer"
                            size="md"
                          />
                          <VStack alignItems={"start"} spacing={0.5}>
                            <Text fontSize="xs" fontWeight={"bold"}>
                              {notification?.title}
                            </Text>
                            {notification?.description && (
                              <Text fontSize="xx-small">
                                {notification?.description}
                              </Text>
                            )}
                            <Text
                              fontSize="xx-small"
                              color={
                                colorMode === "dark" ? "gray.400" : "gray.500"
                              }
                            >
                              {notification?.send_by_user?.organization.name}
                            </Text>
                          </VStack>
                          {showUnread && (
                            <div
                              style={{
                                width: "6px",
                                height: "6px",
                                borderRadius: "100%",
                                backgroundColor: "#2FC0CF",
                                marginLeft: "12px",
                              }}
                            />
                          )}
                        </HStack>
                      </VStack>
                    ))}
                  </React.Fragment>
                )}

                {notifications.length === 0 && (
                  <Flex justifyContent={"center"} alignItems={"center"}>
                    <Image src={NoData} width="150px" height={"150px"} />
                  </Flex>
                )}
              </MenuList>
            </Menu>
          </WrapItem>
          <WrapItem>
            <ChakraLink as={ReactRouterLink} to={ROUTES.settings}>
              <Flex flexDir="column" justify="center" align="center">
                <Image h="24px" src={icon.settings} alt={t("settings")} />
              </Flex>
            </ChakraLink>
          </WrapItem>
        </>
      )}
      <WrapItem>
        <Image
          h="24px"
          src={icon.mode}
          alt={colorMode === "dark" ? t("light-mode") : t("dark-mode")}
          onClick={toggleColorMode}
          cursor="pointer"
        />
      </WrapItem>

      {/* <WrapItem>
        <Select
          w="auto"
          color={colorMode === "dark" ? "white" : "black"}
          value={i18n.language}
          onChange={(e) => changeLanguage(e.target.value)}
          bg={headerBg}
        >
          <option value="en">en</option>
          <option value="fr">fr</option>
          <option value="es">es</option>
          <option value="de">de</option>
          <option value="zh">zh</option>
          <option value="ru">ru</option>
          <option value="ja">ja</option>
          <option value="hi">hi</option>
          <option value="ar">ar</option>
        </Select>
      </WrapItem> */}

      {isAuthenticated && (
        <Menu>
          <MenuButton as={WrapItem} cursor="pointer" _expanded={{ bg: "none" }}>
            <HStack spacing={0}>
              <Avatar
                name={`${auth?.user?.first_name} ${auth?.user?.last_name}`}
                src={auth?.user?.picture || ""}
                bg={auth?.user?.picture ? "" : "brand.primary"}
                color="white"
                cursor="pointer"
                size="sm"
              />
              <VStack align="start" ml={2} spacing={0}>
                <Text fontSize="sm" fontWeight="bold">
                  {`${auth?.user?.first_name} ${auth?.user?.last_name}`}
                </Text>
                {/* <Text fontSize="xs">{auth?.default_role}</Text> */}
              </VStack>
              <Icon as={ChevronDownIcon} w={5} h={5} ml={2} />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<CgProfile size="20px" />}
              as={ReactRouterLink}
              to={ROUTES.profile}
            >
              Profile
            </MenuItem>
            <MenuItem
              icon={<Image src={icon.settings} boxSize="20px" />}
              as={ReactRouterLink}
              to={ROUTES.settings}
            >
              Settings
            </MenuItem>
            <MenuItem
              icon={<Image src={icon.logout} boxSize="20px" />}
              onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Wrap>
  );
};

export default Settings;
