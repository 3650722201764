import { gql } from "urql";

export const LOGIN = gql`
  mutation login($email: String!, $password: String) {
    login(params: { email: $email, password: $password }) {
      message
      access_token
      access_token_expires_at
      user {
        id
        first_name
        last_name
        email
        signup_status
        is_teacher
        user_roles {
          id
          metadata_role {
            id
            role
          }
        }
        user_tags {
          id
          metadata_tag {
            id
            tag
          }
        }
        organization {
          id
          name
          deactivated_at
          verified_at
          max_licenses
          number_of_students
          country
          state
          city
          address_line1
          address_line2
          postal_code
          profile_updated_at
        }
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const SUPPORT_REQUEST = gql`
  mutation support_request($emails: [String!]!, $message: String!) {
    support_request(params: { emails: $emails, message: $message }) {
      message
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verify_email($session: String!, $token: String) {
    verify_email(params: { session: $session, token: $token }) {
      message
      access_token
      access_token_expires_at
      user {
        id
        first_name
        last_name
        email
        signup_status
        is_teacher
        user_roles {
          id
          metadata_role {
            id
            role
          }
        }
        user_tags {
          id
          metadata_tag {
            id
            tag
          }
        }
        organization {
          id
          name
          deactivated_at
          verified_at
          max_licenses
          country
          state
          city
          address_line1
          address_line2
          postal_code
          profile_updated_at
        }
      }
    }
  }
`;

export const GET_USER_ROLES = gql`
  query get_user_roles($names: [String!]) {
    metadata_role(where: { role: { _in: $names } }) {
      id
      role
    }
  }
`;

export const GET_USER_TAGS = gql`
  query get_user_tags($names: [String!]) {
    metadata_tag(where: { tag: { _in: $names } }) {
      id
      tag
    }
  }
`;

export const VERIFY_INVITE = gql`
  mutation verify_invite($data: VerifyInviteRequest!) {
    verify_invite(params: $data) {
      message
      access_token
      access_token_expires_at
      user {
        id
        first_name
        last_name
        email
        signup_status
        is_teacher
        user_roles {
          id
          metadata_role {
            id
            role
          }
        }
        user_tags {
          id
          metadata_tag {
            id
            tag
          }
        }
        organization {
          id
          name
          deactivated_at
          verified_at
          max_licenses
          country
          state
          city
          address_line1
          address_line2
          postal_code
          profile_updated_at
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation create_account($data: CreateAccountRequest!) {
    create_account(params: $data) {
      message
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query get_user_by_id($id: uuid!) {
    users_by_pk(id: $id) {
      id
      first_name
      last_name
      email
      is_teacher
    }
  }
`;

export const UPDATE_USER = gql`
  mutation update_user($id: uuid!, $data: users_set_input!) {
    update_users(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
      }
    }
  }
`;

export const GET_USER_SELF_GUIDED_TRAININGS = gql`
  query get_user_self_guided_trainings($userId: uuid!) {
    user_adult_trainings(where: { user_id: { _eq: $userId } }) {
      id
      completed_at
      created_at
      user_id
      updated_at
      training_id
      workbook
    }
  }
`;

export const UPDATE_COMPLETED_LESSON = gql`
  mutation insert_user_adult_trainings(
    $user_id: uuid
    $training_id: uuid
    $completed_at: timestamptz
    $workbook: jsonb
  ) {
    insert_user_adult_trainings(
      objects: {
        user_id: $user_id
        training_id: $training_id
        completed_at: $completed_at
        workbook: $workbook
      }
      on_conflict: {
        constraint: user_adult_trainings_user_id_training_id_key
        update_columns: [completed_at, workbook]
      }
    ) {
      affected_rows
    }
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation deactivate_account {
    deactivate_account {
      message
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivate_user($user_id: ID!) {
    deactivate_user(params: { user_id: $user_id }) {
      message
    }
  }
`;

export const DEACTIVATE_ORGANIZATION = gql`
  mutation deactivate_organization($organization_id: ID!) {
    deactivate_organization(params: { organization_id: $organization_id }) {
      message
    }
  }
`;

export const REACTIVATE_USER = gql`
  mutation reactivate_user($user_id: ID!) {
    reactivate_user(params: { user_id: $user_id }) {
      message
    }
  }
`;

export const MODIFY_USER_ROLES = gql`
  mutation modify_user_roles($data: ModifyUserRolesRequest!) {
    modify_user_roles(params: $data) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgot_password($email: String!) {
    forgot_password(params: { email: $email }) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation reset_password($data: ResetPasswordRequest!) {
    reset_password(params: $data) {
      message
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation update_password($data: UpdatePasswordRequest!) {
    update_password(params: $data) {
      message
    }
  }
`;

export const GET_USER_ACHIEVEMENTS = gql`
  query get_user_achievements($user_id: uuid!) {
    users(where: { id: { _eq: $user_id } }) {
      id
      first_name
      last_name
      email
      certificate_name_verified_at
    }
    level_one: users(
      where: { pillar: { _contained_in: [true], _contains: true } }
    ) {
      id
    }
    level_two: cohorts(
      where: {
        live_trainings: { user_live_trainings: { user_id: { _eq: $user_id } } }
        live_trainings_aggregate: {
          count: {
            filter: {
              user_live_trainings: {
                user_id: { _eq: $user_id }
                joined_at: { _is_null: true }
              }
            }
            predicate: { _eq: 0 }
          }
        }
      }
    ) {
      id
      cohort_name
      certificate_name
    }
  }
`;

export const GET_USER_PILLAR_STATUS = gql`
  query get_user_by_id($id: uuid!) {
    users_by_pk(id: $id) {
      pillar
    }
  }
`;
