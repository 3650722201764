import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import App from "./App";
import theme from "./theme";
import "./player.css";
import { AuthContextProvider } from "./contexts/auth";
import { CrosstabContextProvider } from "./contexts/crosstab";
import { NotificationProvider } from "./contexts/Notification";
import TourGuideProvider from "./components/common/TourGuideProvider";
import * as Sentry from "@sentry/react";
import { env } from "./config/env";

Sentry.init({
  dsn: env.sentryDSN as string,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "test.contentment.org",
    "school.contentment.org",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter
        future={{ v7_relativeSplatPath: true, v7_startTransition: true }}
      >
        <AuthContextProvider>
          <NotificationProvider>
            <CrosstabContextProvider>
              <TourGuideProvider>
                <App />
              </TourGuideProvider>
            </CrosstabContextProvider>
          </NotificationProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
);
