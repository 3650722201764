import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "demo-page": "Demo Page",
      "navbar-home": "Home",
      "navbar-Training": "Training",
      "navbar-reflection": "Reflection",
      "navbar-teaching": "Teaching",
      "navbar-community": "Community",
      settings: "Settings",
      notifications: "Notifications",
      "light-mode": "Light Mode",
      "dark-mode": "Dark Mode",
      logout: "Logout",
    },
  },
  fr: {
    translation: {
      "demo-page": "Page de démonstration",
      "navbar-home": "Accueil",
      "navbar-Training": "Apprentissage",
      "navbar-reflection": "Enquête",
      "navbar-teaching": "Bibliothèque",
      "navbar-community": "Communauté",
      settings: "Paramètres",
      notifications: "Notifications",
      "light-mode": "Mode clair",
      "dark-mode": "Mode sombre",
      logout: "Déconnexion",
    },
  },
  es: {
    translation: {
      "demo-page": "Página de demostración",
      "navbar-home": "Inicio",
      "navbar-Training": "Aprendizaje",
      "navbar-reflection": "Encuesta",
      "navbar-teaching": "Biblioteca",
      "navbar-community": "Comunidad",
      settings: "Configuración",
      notifications: "Notificaciones",
      "light-mode": "Modo claro",
      "dark-mode": "Modo oscuro",
      logout: "Cerrar sesión",
    },
  },
  de: {
    translation: {
      "demo-page": "Demo-Seite",
      "navbar-home": "Startseite",
      "navbar-Training": "Lernen",
      "navbar-reflection": "Umfrage",
      "navbar-teaching": "Bibliothek",
      "navbar-community": "Gemeinschaft",
      settings: "Einstellungen",
      notifications: "Benachrichtigungen",
      "light-mode": "Lichtmodus",
      "dark-mode": "Dunkelmodus",
      logout: "Abmelden",
    },
  },
  zh: {
    translation: {
      "demo-page": "演示页面",
      "navbar-home": "首页",
      "navbar-Training": "学习",
      "navbar-reflection": "调查",
      "navbar-teaching": "图书馆",
      "navbar-community": "社区",
      settings: "设置",
      notifications: "通知",
      "light-mode": "浅色模式",
      "dark-mode": "深色模式",
      logout: "登出",
    },
  },
  ru: {
    translation: {
      "demo-page": "Демонстрационная страница",
      "navbar-home": "Главная",
      "navbar-Training": "Обучение",
      "navbar-reflection": "Опрос",
      "navbar-teaching": "Библиотека",
      "navbar-community": "Сообщество",
      settings: "Настройки",
      notifications: "Уведомления",
      "light-mode": "Светлый режим",
      "dark-mode": "Темный режим",
      logout: "Выйти",
    },
  },
  ja: {
    translation: {
      "demo-page": "デモページ",
      "navbar-home": "ホーム",
      "navbar-Training": "学習",
      "navbar-reflection": "調査",
      "navbar-teaching": "図書館",
      "navbar-community": "コミュニティ",
      settings: "設定",
      notifications: "通知",
      "light-mode": "ライトモード",
      "dark-mode": "ダークモード",
      logout: "ログアウト",
    },
  },
  hi: {
    translation: {
      "demo-page": "डेमो पेज",
      "navbar-home": "मुख पृष्ठ",
      "navbar-Training": "सीखना",
      "navbar-reflection": "सर्वेक्षण",
      "navbar-teaching": "पुस्तकालय",
      "navbar-community": "समुदाय",
      settings: "सेटिंग्स",
      notifications: "सूचनाएं",
      "light-mode": "लाइट मोड",
      "dark-mode": "डार्क मोड",
      logout: "लॉग आउट",
    },
  },
  ar: {
    translation: {
      "demo-page": "صفحة العرض",
      "navbar-home": "الصفحة الرئيسية",
      "navbar-Training": "التعلم",
      "navbar-reflection": "استطلاع",
      "navbar-teaching": "مكتبة",
      "navbar-community": "مجتمع",
      settings: "الإعدادات",
      notifications: "الإشعارات",
      "light-mode": "الوضع الفاتح",
      "dark-mode": "الوضع الداكن",
      logout: "تسجيل الخروج",
    },
  },
  // Add more languages here as needed
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
