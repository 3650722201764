import { env } from "../config/env";
import { platform } from "../config/graphql";
import { REGISTER_EVENT } from "../graphql/events";
import { AuthData } from "../types/auth";
import { Event } from "../types/events";
import { Operation } from "urql";

export async function registerEvent(
  event: Event,
  headers?: Record<string, string>,
): Promise<void> {
  if (!env.recordEvents) return; // Only record if enabled

  const modifiedheaders: Record<string, string> = {
    "Content-Type": "application/json",
    ...headers,
  };

  // If the event does not have a user_id, try to retrieve one from session storage.
  let userId: string | undefined | null = event.user_id;
  if (!userId) {
    userId = sessionStorage.getItem("cfc_session");
    if (!userId) {
      userId = `session_${Date.now()}`;
      sessionStorage.setItem("cfc_session", userId);
    }
  }

  const variables = {
    data: {
      ...event,
      user_id: userId,
    },
  };

  try {
    const response = await fetch(env.apiURL as string, {
      method: "POST",
      headers: modifiedheaders,
      body: JSON.stringify({
        query: REGISTER_EVENT,
        variables,
        operationName: "register_event",
      }),
    });
    await response.json();
    // console.log("Analytics event registered:", result);
  } catch (err) {
    console.error("Error registering analytics event:", err);
  }
}

// Extracts the operation name from the GraphQL query AST
export const extractOperationName = (operation: Operation): string => {
  let operationName = "unknown_operation";
  try {
    if (
      typeof operation.query !== "string" &&
      operation.query.definitions &&
      Array.isArray(operation.query.definitions)
    ) {
      const opDef = operation.query.definitions.find(
        (def: { kind: string; name?: { value: string } }) =>
          def.kind === "OperationDefinition" && def.name && def.name.value,
      );
      if (opDef && opDef.name && opDef.name.value) {
        operationName = opDef.name.value;
      }
    }
  } catch (e) {
    console.error("Error extracting operation name:", e);
  }
  return operationName;
};

//Builds an analytics event based on the operation.
export const createAnalyticsEvent = (
  operation: Operation,
  auth?: AuthData,
): Event => {
  const operationName = extractOperationName(operation);
  const event: Event = {
    event: operationName || "unknown_event",
    event_type: operation.kind,
    req_data: JSON.stringify({
      operationName,
      variables: operation.variables,
      query:
        typeof operation.query === "string"
          ? operation.query
          : operation.query?.loc?.source.body,
      pathname: window.location.pathname,
      url: window.location.href,
    }),
    other: JSON.stringify({
      platform,
      timestamp: new Date().toISOString(),
      meta: operation.context.meta,
    }),
  };

  if (auth?.user) {
    event.user_id = auth.user.id;
    event.organization_id = auth.user.organization.id;
  }
  return event;
};
