export const PERSONAL_REFLECTION_PAGE: string = "personal_reflection";
export const SCHOOL_REFLECTION_PAGE: string = "school_reflection";

export enum REFLECTION_CATEGORIES {
  Physical_Health = "Physical Health",
  Community_Climate = "Community Climate",
  Inner_Climate = "Inner Climate",
  Relationship_To_Experiences = "Relationship to Experiences",
  Psychological_Wellbeing = "Psychological Wellbeing",
  Student_Climate = "Student Climate",
}

export enum SCORING_TYPES {
  Normal = "Normal",
  Reverse = "Reverse",
}

// Todo: this needs to be updated after changes in DB to Six Point Scale
export enum RESPONSE_TYPES {
  Five_Point_Scale = "Five Point Scale",
}

export const MAX_RESPONSE_VALUE = 6;
export const TOTAL_CATEGORIES = 6;
export const MAX_CATEGORY_SCORE = TOTAL_CATEGORIES * MAX_RESPONSE_VALUE;
export const QUESTIONS_PER_CATEGORY = 6;
export const TOTAL_QUESTIONS = TOTAL_CATEGORIES * QUESTIONS_PER_CATEGORY;

export const CATEGORY_LABELS: Record<string, string> = {
  ph: "Physical Health",
  pwb: "Psychological Wellbeing",
  cc: "Community Climate",
  rs: "Inner Climate",
  eh: "Relationship to Experiences",
  ee: "Educational Efficacy",
};

export const CATEGORY_DESCRIPTIONS: Record<string, string> = {
  ph: "The Physical Health score is how you or your community rated the overall fitness, habits, and behaviors related to bodily wellness.",
  pwb: "The Psychological Wellbeing score is how you or your community rated the overall health, patterns, and mental states related to the wellness of the mind.",
  cc: "The Community Climate score is how you or your community rated the health, vitality, and stability of interpersonal relationships.",
  rs: "The Inner Climate score is how you or your community rated your relationship with an understanding of your inner world.",
  eh: "The Relationship to Experiences score is how you or your community rated how well you react to and relate with the ups and downs of everyday life.",
  ee: "The Educational Efficacy score is how you or your community rated the structure, function, quality, and leadership capabilities of your school.",
};

export const SUB_CATEGORY_LABELS: Record<string, string[]> = {
  ph: [
    "Bodily Comfort",
    "Diet",
    "Exercise",
    "Hydration",
    "Immune System",
    "Rest & Leisure",
    "Sleep",
    "Substance Independence",
  ],
  pwb: [
    "Burnout Buffer",
    "Emotional Wellness",
    "Focus & Flow (Acting with Awareness)",
    "Mindfulness (Emotional Awareness)",
    "Personal Autonomy",
    "Purpose in Life",
    "Satisfaction with Life",
    "Stress Buffer",
  ],
  cc: [
    "Agreement & Trust with Students",
    "Agreement & Trust with Teachers",
    "Appreciation with Students",
    "Appreciation with Teachers",
    "Belonging with Students",
    "Belonging with Teachers",
    "Bullying Buffer & Safety with Students",
    "Bullying Buffer & Safety with Teachers",
    "Collaboration with Students",
    "Collaboration with Teachers",
    "Compassion with Students",
    "Compassion with Teachers",
    "Relationship Satisfaction with Students",
    "Relationship Satisfaction with Teachers",
    "Selfless Service Mindset with Students",
    "Selfless Service Mindset with Teachers",
  ],
  rs: [
    "Experiential Awareness (Mindfulness)",
    "Personal Growth",
    "Self-Compassion",
    "Self-Curiosity",
    "Self-Gratitude",
    "Self-Observation",
    "Unconditional Self-Acceptance",
    "Understanding Emotions",
  ],
  eh: [
    "Appreciation of Enough",
    "Contentment",
    "Emotional Balance",
    "Environmental Mastery",
    "Growth Mindset",
    "Healthy Responsibility With Students",
    "Healthy Responsibility With Teachers",
    "Psychological Flexibility",
    "Situational Gratitude",
  ],
  ee: [
    "Classroom Behavior",
    "Classroom Resources",
    "Educational Quality",
    "Job Satisfaction",
    "Leadership Strength",
    "Personal Resources",
    "Teacher Efficacy",
    "Team Quality",
  ],
};
