import { useMemo } from "react";
import { Image } from "@chakra-ui/react";

import { useThemeContext } from "../../hooks/theme-context";
import contentmentLogo from "../../assets/logo.svg";
import contentmentWhiteLogo from "../../assets/logo_white.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const Logo = () => {
  const { colorMode } = useThemeContext();
  const logo = useMemo(
    () => (colorMode === "dark" ? contentmentWhiteLogo : contentmentLogo),
    [colorMode],
  );
  return (
    <Link to={ROUTES.root}>
      <Image src={logo} h="40px" alt="Contentment Foundation Logo" />
    </Link>
  );
};

export default Logo;
