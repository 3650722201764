import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { MdOutlineSupport } from "react-icons/md";
import { useAuthContext } from "../../hooks/auth-context";
import { useMutation } from "urql";
import { SUPPORT_REQUEST } from "../../graphql/users";
import { ToastConfiguration } from "../../constants/toast";
import { handleGraphQLError } from "../../utils/error";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Support = () => {
  const { auth } = useAuthContext();
  const toast = useToast(ToastConfiguration);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    email: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({
    email: "",
    message: "",
  });
  const [, supportRequestMutation] = useMutation(SUPPORT_REQUEST);

  const handleOnEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormValues({
      ...formValues,
      email: value,
    });
    if (!emailRegex.test(value)) {
      setErrors({
        ...formValues,
        email: "Please enter a valid email address.",
      });
    } else {
      setErrors({
        ...formValues,
        email: "",
      });
    }
  };

  const validate = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!formValues.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    }
    if (!formValues.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      try {
        setLoading(true);
        const { data, error } = await supportRequestMutation({
          emails: [formValues.email],
          message: formValues.message,
        });
        if (error) {
          throw error;
        }
        if (data?.support_request) {
          toast({
            title: "Support Request",
            description: data.support_request.message,
            status: "success",
          });
          onClose();
          setFormValues({
            email: "",
            message: "",
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast({
          title: "Error",
          description: handleGraphQLError(error as Error),
          status: "error",
        });
        console.error(error, "error while handleSubmit");
      }
    }
  };

  const handleOnMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setFormValues({
      ...formValues,
      message: event.target.value,
    });
  };

  useEffect(() => {
    if (auth?.user) {
      setFormValues({
        ...formValues,
        email: auth?.user?.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <React.Fragment>
      <IconButton
        position="fixed"
        bottom="20px"
        right="10px"
        aria-label="Support"
        icon={<MdOutlineSupport size={24} />}
        size="md"
        borderRadius="full"
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        isCentered={true}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Support request</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={4}>
            <VStack
              as="form"
              flex="1"
              spacing={4}
              onSubmit={handleSubmit}
              width="full"
              mt={4}
            >
              <FormControl id="email" isRequired isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  disabled={auth?.user ? true : false}
                  placeholder="Enter email"
                  value={formValues.email}
                  onChange={handleOnEmailChange}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl id="message" isRequired isInvalid={!!errors.message}>
                <FormLabel>Message</FormLabel>
                <Textarea
                  variant={"outline"}
                  placeholder="Enter message"
                  value={formValues.message}
                  onChange={handleOnMessageChange}
                />
                <FormErrorMessage>{errors.message}</FormErrorMessage>
              </FormControl>
              <Divider mt={4} />
              <Flex
                justify="flex-end"
                align={"center"}
                w={"full"}
                mt={1}
                mb={2}
              >
                <Button type="submit" isLoading={loading}>
                  Submit
                </Button>
              </Flex>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

export default Support;
